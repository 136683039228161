import { IoLocationOutline } from 'react-icons/io5';
import { LuPhone } from 'react-icons/lu';
import { MdOutlineEmail } from 'react-icons/md';

const ContactUs = () => {
  return (
    <section
      id="contact-us"
      className="bg-[#F5F5F5] flex justify-center items-center py-[69px]"
    >
      {/* Flex Container */}
      <div className="container py-5 lg:py-0 max-w-unset sm:max-w-[540px] md:max-w-[850px] mx-auto">
        {/* Heading */}
        <div className="flex px-5 md:px-3 flex-col gap-[50px] md:gap-[64px] items-center">
          <h1 className="text-[32px] md:text-[36px] leading-[45px] font-semibold md:font-bold tracking-[-1px] text-[#1D1D1D] text-center">
            Contact Us
          </h1>
          {/* Numbered List */}
          <div className="flex flex-col lg:flex-row justify-center w-full gap-[60px]">
            <div className="flex gap-5">
              <div className="bg-primaryColor h-[50px] w-[50px] p-[14px] rounded-[5px] flex items-center justify-center">
                <IoLocationOutline className="h-[22px] w-[22px] text-white" />
              </div>

              <div className="flex flex-col">
                <p className="text-[17px] font-semibold leading-[25.5px]">
                  Location
                </p>
                <p className="text-[15px] font-medium leading-[22.5px]">
                  Melbourne, Australia
                </p>
              </div>
            </div>

            {/* <div className="flex gap-5">
              <div className="bg-primaryColor h-[50px] w-[50px] p-[14px] rounded-[5px] flex items-center justify-center">
                <LuPhone className="h-[22px] w-[22px] text-white" />
              </div>
              <div className="flex flex-col">
                <p className="text-[17px] font-semibold leading-[25.5px]">
                  Call Us
                </p>
                <p className="text-[15px] font-medium leading-[22.5px]">
                </p>
              </div>
            </div> */}

            <div className="flex gap-5">
              <div className="bg-primaryColor h-[50px] w-[50px] p-[14px] rounded-[5px] flex items-center justify-center">
                <MdOutlineEmail className="h-[22px] w-[22px] text-white" />
              </div>
              <div className="flex flex-col">
                <p className="text-[17px] font-semibold leading-[25.5px]">
                  Email Us
                </p>
                <p className="text-[15px] font-medium leading-[22.5px]">
                  hello@hoversense.com.au
                </p>
              </div>
            </div>
          </div>

          {/* <div className="w-full flex flex-col items-end">
            <div className="flex flex-col md:flex-row w-full items-center justify-between gap-[15px] md:gap-6 mb-[15px]">
              <input
                className="bg-white h-[50px] appearance-none border-2 border-gray-200 rounded w-full px-5 text-gray-700 leading-tight focus:outline-none focus:border-primaryColor"
                id="inline-your-name"
                type="text"
                placeholder="Your Name"
              />
              <input
                className="bg-white h-[50px] appearance-none border-2 border-gray-200 rounded w-full px-5 text-gray-700 leading-tight focus:outline-none focus:border-primaryColor"
                id="inline-your-subject"
                type="text"
                placeholder="Your Subject"
              />
            </div>

            <div className="flex flex-col md:flex-row w-full items-center justify-between gap-[15px] md:gap-6 mb-[15px]">
              <input
                className="bg-white h-[50px] appearance-none border-2 border-gray-200 rounded w-full px-5 text-gray-700 leading-tight focus:outline-none focus:border-primaryColor"
                id="inline-your-email"
                type="text"
                placeholder="Your Email"
              />
              <input
                className="bg-white h-[50px] appearance-none border-2 border-gray-200 rounded w-full px-5 text-gray-700 leading-tight focus:outline-none focus:border-primaryColor"
                id="inline-your-phone"
                type="text"
                placeholder="Your Phone"
              />
            </div>

            <textarea
              id="message"
              rows={6}
              className="bg-white appearance-none border-2 border-gray-200 rounded w-full px-5 py-4 text-gray-700 leading-tight focus:outline-none focus:border-primaryColor"
              placeholder="Your message"
            ></textarea>

            <button
              onClick={() => console.log('Sening message....')}
              className="px-6 py-3 w-fit text-white bg-primaryColor
            font-semibold uppercase rounded-[4px] shadow-2 hover:shadow-4
            hover:bg-[#1c3ab6] mt-[20px]"
            >
              Send Message
            </button>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
