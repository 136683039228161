import { useEffect, useLayoutEffect, useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { BsChevronUp } from 'react-icons/bs';

interface Props {
  children: any;
}
const navMenu: any = [
  {
    title: 'Home',
    url: '/',
    id: 'hero',
  },
  {
    title: 'About Us',
    url: '/',
    id: 'about-us',
  },
  {
    title: 'Products',
    url: '/',
    id: 'products',
  },
  {
    title: 'Services',
    url: '/',
    id: 'services',
  },
  {
    title: 'Contact',
    url: '/',
    id: 'contact-us',
  },
];

const clamp = (value: number) => Math.max(0, value);

const isBetween = (value: number, floor: number, ceil: number) =>
  value >= floor && value <= ceil;

// hooks
const useScrollspy = (menu: string[], offset: number = 0) => {
  const [activeId, setActiveId] = useState('');

  useLayoutEffect(() => {
    const listener = () => {
      const scroll = window.pageYOffset;

      const position = menu
        .map((item: any) => {
          const element = document.getElementById(item.id);

          if (!element) return { id: item.id, top: -1, bottom: -1 };

          const rect = element.getBoundingClientRect();
          const top = clamp(rect.top + scroll - offset);
          const bottom = clamp(rect.bottom + scroll - offset);

          return { id: item.id, top, bottom };
        })
        .find(({ top, bottom }) => isBetween(scroll, top, bottom));

      setActiveId(position?.id || '');
    };

    listener();

    window.addEventListener('resize', listener);
    window.addEventListener('scroll', listener);

    return () => {
      window.removeEventListener('resize', listener);
      window.removeEventListener('scroll', listener);
    };
  }, [menu, offset]);

  const smoothScrollTo = (id: any) => {
    const element = document.getElementById(id);

    if (element) {
      window.scrollTo({
        top: element.offsetTop - offset + 10,
        behavior: 'smooth',
      });
    }
  };

  return { activeId, smoothScrollTo };
};

export const Layout = (props: Props) => {
  const { children } = props;
  const { activeId, smoothScrollTo } = useScrollspy(navMenu, 80);

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    // You can adjust the threshold based on your design
    setIsVisible(scrollY > 10);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar
        activeId={activeId}
        smoothScrollTo={smoothScrollTo}
        navMenu={navMenu}
      />
      {children}

      <Footer />

      <button
        className={`scroll-to-top-button transition-all duration-300 ease-out delay-0 hover:bg-black transform hover:-translate-y-[5px] flex items-center justify-center fixed right-[30px] h-[45px] w-[45px] cursor-pointer rounded-[5px] bg-primaryColor bottom-[30px] ${
          isVisible ? 'visible' : 'hidden'
        }`}
        onClick={scrollToTop}
      >
        <BsChevronUp className="text-white text-base" />
      </button>
    </>
  );
};
