import { Link } from 'react-router-dom';
import { CloudBottom, CloudTop } from '../assets/icons';
import MainCloudImage from '../assets/images/main-cloud.png';
import MainCloudSmallImage from '../assets/images/main-cloud-small.png';
import FlightImage from '../assets/images/flight-paper.png';

const Hero = () => {
  return (
    <section
      id="hero"
      className="pt-[160px] h-[780px] md:h-[805px] pb-[100px] bg-[#F7FAFF] relative"
    >
      {/* Flex Container */}
      <div className="container absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 max-w-unset md:max-w-[734px] flex flex-col-reverse items-center m-auto">
        <div className="flex relative z-[100] px-5 md:px-3 flex-col gap-5 items-center justify-center">
          <h1 className="mt-[12px] sm:mt-0 mr-[30px] sm:mr-0 text-[32px] md:text-[44px] max-w-[250px] sm:max-w-fit font-semibold leading-[40px] md:leading-[55px] tracking-[-1px] text-[#1D1D1D] text-center ">
            Cloud Based EDI Solution
          </h1>

          <p className="text-center text-sm sm:text-base mt-[60px] sm:mt-0 text-[#6D6D6D] font-normal max-w-[600px]">
            We provide end to end EDI integration solution in supply chain. This
            is your one stop shop to an innovated, secure, reliable and
            cost-effective EDI solution.
          </p>
        </div>

        <img
          src={MainCloudImage}
          alt=""
          className="absolute hidden sm:block top-[-120px] md:top-[-235px] left-[50%] md:left-[53%] transform -translate-x-1/2 "
        />

        <img
          src={MainCloudSmallImage}
          alt=""
          className="absolute block sm:hidden top-[-76px] left-[50%] transform -translate-x-1/2 "
        />

        <img
          src={FlightImage}
          alt=""
          className="absolute hidden xl:block bottom-[-280px] left-[-290px]"
        />
      </div>

      <CloudBottom className="absolute w-[135px] h-[80px] md:w-[234px] md:h-[140px] right-[23px] md:right-[50px] bottom-[140px] md:bottom-[110px]" />
      <CloudTop className="absolute w-[111px] h-[68px] md:w-[150px] md:h-[90px] top-[110px] md:top-[150px] left-[23px] md:left-[130px]" />
      <img
        src={FlightImage}
        alt=""
        className="absolute w-[203px] h-[255px] block md:hidden bottom-[0] left-[0]"
      />
    </section>
  );
};

export default Hero;
