import { Link } from 'react-router-dom';

import { FaFacebookF } from 'react-icons/fa6';
import { FaTwitter } from 'react-icons/fa';
import { RiInstagramFill } from 'react-icons/ri';
import { GrLinkedin } from 'react-icons/gr';
import { LogoFooter } from '../assets/icons';

const Footer = () => {
  return (
    <div id="footer" className="flex flex-col">
      <div className="container m-auto pt-[100px] pb-[60px]">
        <div className="flex px-5 md:px-3 flex-col items-center justify-center">
          <LogoFooter className="mb-2" />
          <p className="mt-[30px] mb-[20px] text-center text-[18px] font-semibold">
            Follow Us On
          </p>
          <div className="flex justify-center space-x-4">
            {/* Link 1 */}
            <Link
              to="#"
              className="hover:bg-primaryColor h-[45px] w-[45px] rotate-hover rounded-full border-[1px] border-solid border-[#e0e0e0] hover:border-transparent flex items-center justify-center text-[#6d6d6d] hover:text-white"
            >
              <FaFacebookF className="h-[18px] w-[18px]" />
            </Link>
            {/* Link 2 */}
            <Link
              to="#"
              className="hover:bg-primaryColor h-[45px] w-[45px] rotate-hover rounded-full border-[1px] border-solid border-[#e0e0e0] hover:border-transparent flex items-center justify-center text-[#6d6d6d] hover:text-white"
            >
              <FaTwitter className="h-[18px] w-[18px]" />
            </Link>
            {/* Link 3 */}
            <Link
              to="#"
              className="hover:bg-primaryColor h-[45px] w-[45px] rotate-hover rounded-full border-[1px] border-solid border-[#e0e0e0] hover:border-transparent flex items-center justify-center text-[#6d6d6d] hover:text-white"
            >
              <RiInstagramFill className="h-[18px] w-[18px]" />
            </Link>
            {/* Link 4 */}
            <Link
              to="#"
              className="hover:bg-primaryColor h-[45px] w-[45px] rotate-hover rounded-full border-[1px] border-solid border-[#e0e0e0] hover:border-transparent flex items-center justify-center text-[#6d6d6d] hover:text-white"
            >
              <GrLinkedin className="h-[18px] w-[18px]" />
            </Link>
          </div>
        </div>
      </div>

      <div className="py-[25px] px-5 md:px-3 text-[#6D6D6D] text-center border-[1px] border-[#E0E0E0] border-solid">
        Copyright © 2024 HowerSense. All Rights Reserved
      </div>
    </div>
    // <div className="bg-veryDarkBlue">
    //   {/* Flex Container */}
    //   <div className="container flex flex-col-reverse justify-between px-6 py-10 mx-auto space-y-8 md:flex-row md:space-y-0">
    //     {/* Logo and social links container */}
    //     <div className="flex flex-col-reverse items-center justify-between space-y-12 md:flex-col md:space-y-0 md:items-start">
    //       <div className="mx-auto my-6 text-center text-white md:hidden">
    //         Copyright © 2022, All Rights Reserved
    //       </div>
    //       {/* Logo */}
    //       <div>
    //         <img src={companyLogoWhite} className="h-8" alt="" />
    //       </div>
    //       {/* Social Links Container */}
    //       <div className="flex justify-center space-x-4">
    //         {/* Link 1 */}
    //         <Link to="#">
    //           <img src={facebookLogo} className="h-8" alt="" />
    //         </Link>
    //         {/* Link 2 */}
    //         <Link to="#">
    //           <img src={youtubeLogo} className="h-8" alt="" />
    //         </Link>
    //         {/* Link 3 */}
    //         <Link to="#">
    //           <img src={twitterLogo} className="h-8" alt="" />
    //         </Link>
    //         {/* Link 4 */}
    //         <Link to="#">
    //           <img src={pinterestLogo} className="h-8" alt="" />
    //         </Link>
    //         {/* Link 5 */}
    //         <Link to="#">
    //           <img src={instagramLogo} className="h-8" alt="" />
    //         </Link>
    //       </div>
    //     </div>
    //     {/* List Container */}
    //     <div className="flex justify-around space-x-32">
    //       <div className="flex flex-col space-y-3 text-white">
    //         <Link to="#" className="hover:text-brightRed">
    //           Home
    //         </Link>
    //         <Link to="#" className="hover:text-brightRed">
    //           Pricing
    //         </Link>
    //         <Link to="#" className="hover:text-brightRed">
    //           Products
    //         </Link>
    //         <Link to="#" className="hover:text-brightRed">
    //           About
    //         </Link>
    //       </div>
    //       <div className="flex flex-col space-y-3 text-white">
    //         <Link to="#" className="hover:text-brightRed">
    //           Careers
    //         </Link>
    //         <Link to="#" className="hover:text-brightRed">
    //           Community
    //         </Link>
    //         <Link to="#" className="hover:text-brightRed">
    //           Privacy Policy
    //         </Link>
    //       </div>
    //     </div>

    //     {/* Input Container */}
    //     <div className="flex flex-col justify-between">
    //       <form>
    //         <div className="flex space-x-3">
    //           <input
    //             type="text"
    //             className="flex-1 px-4 rounded-full focus:outline-none"
    //             placeholder="Updated in your inbox"
    //           />
    //           <button className="px-6 py-2 text-white rounded-full bg-brightRed hover:bg-brightRedLight focus:outline-none">
    //             Go
    //           </button>
    //         </div>
    //       </form>
    //       <div className="hidden text-white md:block">
    //         Copyright © 2022, All Rights Reserved
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Footer;
