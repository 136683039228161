import { useEffect } from 'react';
import AboutUs from '../../components/AboutUs';
import ContactUs from '../../components/ContactUs';
import Hero from '../../components/Hero';
import { Layout } from '../../components/Layout';
import Products from '../../components/Products';
import Services from '../../components/Services';

export const HomePage = () => {
  useEffect(() => {
    const scrollOptions: ScrollToOptions = {
      top: 0,
      behavior: 'smooth',
    };

    // Scroll to the top when the component mounts
    window.scrollTo(scrollOptions);

    // Additionally, you might want to scroll to the top on route changes
    const handleRouteChange = () => {
      window.scrollTo(scrollOptions);
    };

    // Listen for route changes and scroll to the top
    window.addEventListener('popstate', handleRouteChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, []);

  return (
    <Layout>
      <Hero />
      <AboutUs />
      <Products />
      <Services />
      <ContactUs />
    </Layout>
  );
};
