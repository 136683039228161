import dotsIcon from '../assets/images/icon-dots.svg';
import circleIcon from '../assets/images/icon-circle.svg';
import serviceOne from '../assets/images/service1.svg';
import serviceTwo from '../assets/images/service2.svg';
import serviceThree from '../assets/images/service3.svg';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IoIosArrowBack } from 'react-icons/io';
import { useRef, useState } from 'react';
import { Slide } from './Slide';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'usehooks-ts';

function SampleNextArrow(props: any) {
  const { className, onClick } = props;
  return (
    <div
      className={`${className} cursor-pointer w-[32px] h-[32px] md:w-[48px] md:h-[48px] bg-primaryColor rounded-full text-white flex items-center justify-center absolute right-[-4px] md:right-[-20px] top-1/2 transform -translate-y-1/2`}
      onClick={onClick}
    >
      <IoIosArrowBack className="rotate-180 w-[16px] h-[16px] md:w-[24px] md:h-[24px]" />
    </div>
  );
}

function SamplePrevArrow(props: any) {
  const { className, onClick } = props;

  return (
    <button
      className={`${className} cursor-pointer w-[32px] h-[32px] md:w-[48px] md:h-[48px] bg-primaryColor rounded-full text-white flex items-center justify-center absolute left-[-4px] md:left-[-20px] top-1/2 transform -translate-y-1/2`}
      onClick={onClick}
    >
      <IoIosArrowBack className="w-[16px] h-[16px] md:w-[24px] md:h-[24px]" />
    </button>
  );
}

const Products = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();

  const [currentSlide, setCurrentSlide] = useState<number>(0);

  var settings = {
    dots: false,
    infinite: true,
    speed: 250,
    className: width > 992 ? 'center' : '',
    centerMode: width > 992 ? true : false,
    slidesToShow: width < 992 ? 1 : 3,
    slidesToScroll: 1,
  };

  const sliderRef = useRef<Slider>(null);

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const services = [
    {
      src: serviceTwo,
      id: 2,
    },
    {
      src: serviceOne,
      id: 1,
    },
    {
      src: serviceThree,
      id: 3,
    },
    
    {
      src: serviceTwo,
      id: 2,
    },
    {
      src: serviceOne,
      id: 1,
    },
    {
      src: serviceThree,
      id: 3,
    },
  ];

  return (
    <section id="products">
      <div className="flex flex-col bg-[#F5F5F5] py-[70px] md:py-[100px] px-[26px] md:px-0">
        {/* Container to heading and testm blocks */}
        <div className="container max-w-unset sm:max-w-[540px] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px] 2xl:max-w-[1320px] h-[321px] md:h-[528px] max-w-unset mx-auto">
          <div className="bg-white overflow-hidden h-full flex flex-col items-center rounded-[4px] md:rounded-[15px] justify-between pt-[40px] pb-[46px] px-[18px] md:pt-[26px] md:pb-[36px] md:px-[36px] relative">
            <p className="text-[#331B3B] text-center text-[36px] md:text-[44px] leading-[46px] font-semibold md:font-bold">
              Products
            </p>
            <div className="w-full slider flex-1 min-h-[160px] my-[32px]">
              <Slider ref={sliderRef} {...settings}>
                {services.map((item, index) => (
                  <div key={index}>
                    <img
                      src={item.src}
                      alt="image products"
                      className={`${item.id !== 1 ? 'shadow-md' : ''} image`}
                    />
                  </div>
                ))}
              </Slider>
              <SamplePrevArrow onClick={previous} />
              <SampleNextArrow onClick={next} />
            </div>

            <img
              src={circleIcon}
              alt=""
              className="absolute h-[70px] w-[70px] md:h-[83px] md:w-[81px] bottom-[-18px] md:bottom-0 right-[-30px] md:right-0"
            />
            <img
              src={dotsIcon}
              alt=""
              className="absolute top-[12px] md:top-[64px] right-[14px] md:right-[30px] h-[20px] md:h-[30px] w-[70px] md:w-[110px]"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Products;
