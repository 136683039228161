import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { LogoNavbar } from '../assets/icons';

interface NavbarProps {
  activeId: string;
  navMenu: any;
  smoothScrollTo: any;
}

const Navbar = (props: NavbarProps) => {
  const { activeId, navMenu, smoothScrollTo } = props;
  const [openDropdown, setOpenDropDown] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <nav className="fixed top-0 right-0 left-0 bg-primaryColor w-full z-[100]">
      {/* Flex Container */}
      <div className="container py-5 lg:py-0 max-w-unset sm:max-w-[540px] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px] 2xl:max-w-[1320px] mx-auto">
        <div className="flex px-5 md:px-3 items-center justify-between">
          {/* Logo */}
          <div className="w-[176px] h-[27px]">
            <LogoNavbar
              className="cursor-pointer"
              onClick={() => navigate('/')}
            />
          </div>

          {/* Menu Items */}
          <div className="hidden lg:flex">
            {navMenu
              .filter((item: any) => item.title !== 'Contact')
              .map((item: any, index: number) => {
                return (
                  <button
                    onClick={() => {
                      if (location.pathname !== '/products')
                        smoothScrollTo(item.id);
                      else navigate('/');
                    }}
                    key={index}
                    className={clsx(
                      'nav-item flex items-center gap-2 py-[28px] mx-[5px] font-semibold text-white text-base px-[15px]',
                      item.id === activeId && 'nav-item-active',
                      item.title === 'Products' && 'relative',
                    )}
                  >
                    {item.title}
                    {/* {item.title === 'Products' && (
                      <>
                        <IoIosArrowDown
                          className={`${
                            openDropdown ? 'rotate-180' : 'rotate-0'
                          } transition-transform duration-200 transform`}
                        />

                        <div
                          style={{ display: openDropdown ? 'block' : 'none' }}
                          className="absolute left-0 w-full rounded-md shadow-lg md:w-48"
                        >
                          <div className="px-2 py-2 bg-white rounded-md shadow dark-mode:bg-gray-800">
                            <a
                              className="block px-4 py-2 text-sm font-semibold text-black"
                              href="#"
                            >
                              EDI Website
                            </a>
                          </div>
                        </div>
                      </>
                    )} */}
                  </button>
                );
              })}
          </div>

          <div className="flex items-center gap-8">
            {/* Button */}
            <button
              onClick={() => smoothScrollTo('contact-us')}
              className="hidden px-6 py-3 h-[46px] text-primaryColor bg-white
              text-center text-base rounded-[4px] border-[1px] items-center border-solid
              font-semibold hover:border-white hover:bg-transparent hover:text-white sm:flex"
            >
              Contact
            </button>

            {/* Hamburger Icon */}
            <div className="py-1 px-3 block lg:hidden">
              <button
                className={`${
                  toggleMenu && 'open'
                } block hamburger lg:hidden focus:outline-none`}
                onClick={() => setToggleMenu(!toggleMenu)}
              >
                <span className="hamburger-top"></span>
                <span className="hamburger-middle"></span>
                <span className="hamburger-bottom"></span>
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        <div className="lg:hidden ">
          <div
            className={`${
              toggleMenu
                ? 'animate-fade-in-down opacity-100'
                : 'fade-in-up hidden'
            } absolute w-[90%] max-w-unset sm:max-w-[540px] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px] 2xl:max-w-[1320px]
            flex flex-col self-end py-[10px] mt-[19px] font-bold bg-primaryColor sm:self-center drop-shadow-md left-1/2 transform -translate-x-1/2`}
          >
            {navMenu.map((item: any, index: number) => {
              return (
                <Link
                  to={item.url}
                  key={index}
                  onClick={() => {
                    if (location.pathname !== '/products')
                      smoothScrollTo(item.id);
                    else navigate('/');
                  }}
                  className={`ml-[5px] nav-item mb-[5px] text-base p-[10px] font-semibold text-white ${
                    item.title === 'Contact' && 'block sm:hidden'
                  }  ${item.id === activeId && 'nav-item-active'}`}
                >
                  {item.title}
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
