import { CheckedIcon } from '../assets/icons';
import AboutUsImage from '../assets/images/about-us.png';

const aboutUsInfo = [
  {
    title: 'Our Team',
    description:
      'We have team of experts in cloud architecture, full stack software development, UI/UX, security, system engineering and project management.',
  },
  {
    title: 'Our Technologies',
    description:
      'We ensure secure document exchange between you and your trading partner via AS2 connection. We automate communication between your on premise ERPs and our cloud service via APIs.',
  },
  {
    title: 'Why Choose Us?',
    description:
      'High quality of serveice and support, zero down time, cost-effective integration solution and low monthly fee.',
  },
];

const AboutUs = () => {
  return (
    <section
      id="about-us"
      className="flex justify-center items-center bg-white py-[60px] md:py-[100px]"
    >
      {/* Container to heading and testm blocks */}
      <div className="container max-w-unset sm:max-w-[540px] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px] 2xl:max-w-[1320px] flex flex-col justify-center items-center m-auto">
        <div className="flex px-5 md:px-3 flex-col gap-3 md:gap-[30px] items-center justify-center">
          <h1 className="text-[32px] md:text-[36px] leading-[45px] font-semibold md:font-bold tracking-[-1px] mb-2 md:mb-4 text-[#1D1D1D] text-center">
            About Us
          </h1>

          <p className="text-center text-base text-[#6D6D6D] font-normal max-w-unset md:max-w-[865px]">
            Based in Australia, we have experience in providing integrating
            solution for large logistics companies and retailers. We are
            dedicated to serve our customers with our expert Electronic Data
            Interchange (EDI) solutions. We pride ourselves on delivering
            bespoke, high-quality and secure EDI integration solution by
            leveraging cloud resources and on-premise ERP/TMS systems.
          </p>
        </div>

        <div className="flex flex-col-reverse xl:flex-row items-center gap-[50px]">
          <div className="flex flex-col gap-5 xl:gap-[27px] w-full xl:w-[37%]">
            {aboutUsInfo.map((info: any, index: number) => {
              return (
                <div key={index} className="flex gap-[8px] px-5 md:px-3">
                  <div className="w-[15px] h-[15px]">
                    <CheckedIcon className="w-[15px] h-[15px] mt-[4px]" />
                  </div>
                  <div className="flex flex-col gap-[6px]">
                    <p className="text-[18px] leading-[24px] font-semibold md:font-bold text-black">
                      {info.title}
                    </p>
                    <p className="text-[14px] md:text-[16px] leading-[24px] font-normal text-[#6D6D6D]">
                      {info.description}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <img src={AboutUsImage} alt="" className="flex-1" />
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
