import React from 'react';
import { EDISolutionIcon, AS2Icon, DataAnalysicIcon } from '../assets/icons';

const features = [
  {
    icon: <EDISolutionIcon />,
    title: 'EDI Integration Solution',
    description:
      'We provide end to end integeration solution from initial consultantion to the final project delivery.',
  },
  {
    icon: <AS2Icon />,
    title: 'AS2 Connectivity',
    description:
      'We offer cost-effective standalone AS2 connectivity as service to ensure secure data exchange between you and your trading partners.',
  },
  {
    icon: <DataAnalysicIcon />,
    title: 'Data Analytics and Report',
    description:
      'We provide custom web portal to help manage, visualize and analyze your EDI documents.',
  },
];

const Services = () => {
  return (
    <section
      id="services"
      className="pb-[42px] pt-[80px] md:py-[100px] min-h-[540px] md:min-h-[725px] bg-white"
    >
      {/* Flex Container */}
      <div className="container max-w-unset sm:max-w-[540px] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px] 2xl:max-w-[1320px] flex flex-col m-auto">
        {/* What's Different */}
        <div className="flex px-5 md:px-3 flex-col items-center">
          <div className="flex flex-col max-w-[500px] mb-6 md:mb-[80px]">
            <h1 className="text-[32px] md:text-[36px] leading-[45px] font-semibold md:font-bold tracking-[-1px] mb-2 md:mb-4 text-[#1D1D1D] text-center">
              Services
            </h1>

            <p className="text-center text-base text-[#6D6D6D] font-normal">
              We offer a range of EDI services, and provide consultancy and
              solution tailered to your requirements.
            </p>
          </div>

          {/* Numbered List */}
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
            {/* Mapping Item */}
            {features.map((feature: any, index: number) => {
              return (
                <div
                  key={index}
                  className="service-item flex flex-col rounded-[5px] shadow-md px-[40px] py-[50px] md:p-[50px] border-[1px] border-solid border-[#FAFAFA]"
                >
                  <div className="info flex flex-col mb-[30px] flex-1">
                    {feature.icon}

                    <h3 className="mb-6 mt-7 text-[20px] leading-[24px] md:text-[24px] md:leading-[30px] font-semibold">
                      {feature.title}
                    </h3>
                    <p className="text-[13px] leading-[20px] md:text-base md:leading-[24px] text-[#6D6D6D] font-normal">
                      {feature.description}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
